import theme from "@giraldomac/gatsby-theme-wvumedicine/src/@chakra-ui/gatsby-plugin/theme"

import merge from "deepmerge"

const cancerTheme = merge(theme, {
  colors: { ...theme.colors, lightblue: "aliceblue" },
  styles: {
    ...theme.styles,
  },
})

export default cancerTheme
