module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WVU Medicine","short_name":"WVUMedicine","start_url":"/","background_color":"#fff","theme_color":"#ccc","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2434ce729ef8f1ea9f59c58d4242cd90"},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"isResettingCSS":true,"isUsingColorMode":true},
    },{
      plugin: require('../node_modules/@giraldomac/gatsby-theme-wvumedicine/gatsby-browser.js'),
      options: {"plugins":[],"datoCmsApiToken":"5cf4d4b73d4c07a68c66282b8e8a83","previewMode":false,"disableLiveReload":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"wvu-medicine","short_name":"wvumedicine","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"/opt/build/repo/src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2434ce729ef8f1ea9f59c58d4242cd90"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page","/404","/404.html","/offline-plugin-app-shell-fallback"],"crumbLabelUpdates":[{"pathname":"/about-us","crumbLabel":"About Us"},{"pathname":"/patients-visitors","crumbLabel":"Patients & Visitors Resources"},{"pathname":"/about-us/inside-the-institute","crumbLabel":"Inside The Institute"},{"pathname":"/patients-visitors/cancers-we-treat","crumbLabel":"Cancers We Treat"},{"pathname":"/about-us/programs/mobile-cancer-screening-program","crumbLabel":"Mobile Cancer Screening Program"}],"usePathPrefix":"/blog"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-3ZE7MSQWCS"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
